import React from 'react'
import Layout from '../components/Layout'
import ReactPlayer from 'react-player'

const TestReactPlayer = () => {
  return (
    <Layout>
      <div>Test</div>
      <ReactPlayer
        className="react-player"
        controls
        url="https://vimeo.com/422966228"
        config={{
          vimeo: {
            playerOptions: {
              playsinline: false,
            },
          },
        }}
      />
    </Layout>
  )
}

export default TestReactPlayer
