import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import Header from '../Header'
import Sidebar from '../Sidebar'
import Footer from '../Footer'
import './Layout.css'

class Layout extends Component {
  constructor({ children }) {
    super()
    this.state = {
      children,
      sidebarToggled: false,
    }

    this.toggleSidebar = this.toggleSidebar.bind(this)
    this.handleResize = this.handleResize.bind(this)
  }

  toggleSidebar() {
    this.setState({ sidebarToggled: !this.state.sidebarToggled })
  }

  handleResize() {
    if (window.innerWidth >= 800 && this.state.sidebarToggled) {
      this.toggleSidebar()
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount() {
    window.addEventListener('resize', this.handleResize)
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <div className="journal">
            <div>
              <Header
                toggleSidebar={this.toggleSidebar}
                sidebarToggled={this.state.sidebarToggled}
                siteTitle={data.site.siteMetadata.title}
              />
            </div>
            <div className="appContainer">
              <Sidebar
                sidebarToggled={this.state.sidebarToggled}
                className="sidebar"
              />
              <div className={'contents'}>{this.state.children}</div>
            </div>
            <Footer />
          </div>
        )}
      />
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
