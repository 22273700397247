import React from 'react'
import { Link } from 'gatsby'
import './Sidebar.css'

const Sidebar = ({ sidebarToggled }) => (
  <div className={sidebarToggled ? 'abs-sidebar' : 'sidebar'}>
    <div className="linkContainer">
      <Link to="/issues" className="headline-lg sidebarOption">
        Issues
      </Link>
      <Link to="/about" className="headline-lg sidebarOption">
        About
      </Link>
      <Link to="/contact" className="headline-lg sidebarOption">
        Contact
      </Link>
      <Link to="/mailing-list" className="headline-lg sidebarOption">
        Mailing List
      </Link>
    </div>
  </div>
)

export default Sidebar
