import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import './Footer.css'

function Icons() {
  return (
    <StaticQuery
      query={graphql`
        query IGIconQuery {
          icons: allFile(
            filter: { relativePath: { in: ["icons/fb.png", "icons/ig.png"] } }
          ) {
            edges {
              node {
                name
                childImageSharp {
                  id
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        return data.icons.edges.map((icon, key) => {
          return (
            <a
              href={
                icon.node.name === 'ig'
                  ? 'https://www.instagram.com/contangojournal'
                  : 'https://www.facebook.com/contango.us'
              }
              key={key}
            >
              <Img
                className="socialIcon"
                fluid={icon.node.childImageSharp.fluid}
              />
            </a>
          )
        })
      }}
    />
  )
}

export default class Footer extends Component {
  render() {
    return (
      <div className="footer">
        <div className="socialIcons">
          <Icons />
        </div>
        <div>
          <p>
            <a className="emailAddress" href="mailto:connect@contango.us">
              CONNECT@CONTANGO.US
            </a>
          </p>
        </div>
        <div>{/* Spacer Element */}</div>
      </div>
    )
  }
}
