import React, { Component } from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import './Header.css'

import closeIcon from '../../images/icons/mui-close.svg'
import menuIcon from '../../images/icons/mui-menu.svg'

export default class Header extends Component {
  state = {
    scrollTop: true,
  }

  handleScroll = () => {
    this.setState({ scrollTop: window.scrollY < 25 })
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  render() {
    return (
      <div className={this.state.scrollTop ? 'navbar' : 'navbar solid'}>
        <div className="navbarContainer">
          <StaticQuery
            query={graphql`
              query LogoQuery {
                logo: file(relativePath: { eq: "logo.png" }) {
                  childImageSharp {
                    id
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            `}
            render={data => (
              <Link to="/">
                <Img className="logo" fluid={data.logo.childImageSharp.fluid} />
              </Link>
            )}
          />
          <div className="hamburger">
            <img
              alt={this.props.sidebarToggled ? 'Close Menu' : 'Open Menu'}
              className="menuIcon"
              src={this.props.sidebarToggled ? closeIcon : menuIcon}
              onClick={this.props.toggleSidebar}
            />
          </div>
        </div>
      </div>
    )
  }
}
